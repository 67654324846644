import InterglobalLogo from './assets/interglobal-negro.png';
import whatsapp from './assets/whatsapp.png';
import './App.scss';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <img className='sidebar-logo' src={InterglobalLogo} alt='interglobal-logo' />
        <div className="header-sections">
          <a href='#our-services'>Nuestros servicios</a>
          <div className="horizontal-separator"></div>
          <a href='#mission-and-vision'>Misión/Visión</a>
          <div className="horizontal-separator"></div>
          <a href='https://install.interglobal-imc.com.co' target='_blank' rel="noreferrer">Instalaciones</a>
        </div>
      </header>
      <div className="backgroun-image-section">
      </div>
      <section>
      </section>
      <section id="our-services">
        <h1>Nuestros Servicios</h1>
        <div className='vertical-separator'></div>
        <span>
          Somos una empresa fundada en Bello, Colombia.
          Prestamos servicio de comunicación que brinda acceso a Internet sin clausula de permanencia o penalizaciones,
          permitiendo así llegar a más usuarios y acercarnos a quien lo requiera.
        </span>
      </section>
      <section id="mission-and-vision">
        <h1>
          Misión y Visión
          <div className='vertical-separator'></div>
        </h1>

        <span className='mission'>
          Alcanzar un total de mínimo 500 usuarios para el año 2025, esto con el fin de crecer como empresa y
          así generar posibilidades de empleo mejorando la calidad de vida de las personas.
        </span>
        <span className='vision'>
          Ser reconocidos a nivel local como una empresa generadora de empleo, con alta calidad y variedad de servicios.
        </span>
      </section>
      <footer className="app-footer">
        <span>Interglobal IMC</span>
        <div className="contact-us">
          <img className="whatsapp-logo" src={whatsapp} alt='whatsapp-logo' />
          <span>Contáctanos: 3007770240</span>
        </div>
      </footer>
    </div>
  );
}

export default App;
